import React from "react"
import Layout from "../../components/layout"
import CommentsFB from "../../components/Comments"
import { GatsbySeo } from "gatsby-plugin-next-seo"

const Poemas = () => {
  return (
    <Layout>
      <GatsbySeo
        title="Frutos de la Memoria - Poemas"
        titleTemplate="%s | Eduardo Ceballos"
        description="Frutos de la Memoria"
        canonical="https://eduardoceballos.com/poemas/frutos-de-la-memoria"
        openGraph={{
          url: "https://eduardoceballos.com/poemas/frutos-de-la-memoria",
          title: "Frutos de la Memoria - Poemas",
          description: "Frutos de la Memoria",
          images: [
            {
              url: "https://eduardoceballos.com/seo/poemas.jpg",
              alt: "Poemas",
            },
          ],
          site_name: "Eduardo Ceballos",
        }}
        twitter={{
          handle: "@PoetadeSalta",
          site: "@PoetadeSalta",
          cardType: "summary_large_image",
        }}
      />
      <h1>Frutos de la Memoria</h1>
      <center>
        <table
          style={{
            maxWidth: "800px",
          }}
          border="2"
          cellpadding="10"
          cellspacing="0"
          width="100%"
        >
          <tbody>
            <tr></tr>
            <tr>
              <td align="left" valign="top">
                <p>
                  <b>
                    <span> No me olvides</span>
                  </b>
                </p>
                <p>
                  <span> &nbsp;</span>
                </p>
                <p>
                  <span> ¿Por qué me olvidas?</span>
                </p>
                <p>
                  <span> ¿No te das cuenta </span>
                </p>
                <p>
                  <span> que sin tí no sé vivir?</span>
                </p>
                <p>
                  <span> Lo que junta el amor</span>
                </p>
                <p>
                  <span> es difícil separar.</span>
                </p>
                <p>
                  <span> Por eso te pido amor,</span>
                </p>
                <p>
                  <span> no me olvides.</span>
                </p>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <p>
                  <span>
                    <b>El que toca se llama Germán</b>
                  </span>
                </p>
                <p>&nbsp;</p>
                <p>
                  <span> Desde las alturas bajan claras</span>
                </p>
                <p>
                  <span> voces y guitarras</span>
                </p>
                <p>
                  <span> que como el agua</span>
                </p>
                <p>
                  <span> dibujan geografías.</span>
                </p>
                <p>
                  <span> &nbsp;</span>
                </p>
                <p>
                  <span> En el justo rincón de la nostalgia</span>
                </p>
                <p>
                  <span> nombramos a Germán</span>
                </p>
                <p>
                  <span> y levantamos este altar del canto</span>
                </p>
                <p>
                  <span> en tu corazón de pueblo</span>
                </p>
                <p>
                  <span> que vive emocionado</span>
                </p>
                <p>
                  <span> de recibir pañuelos y afectos.</span>
                </p>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <p>
                  <span>
                    <b>
                      <span>Las tinajas de Dionisio Díaz</span>
                    </b>
                  </span>
                </p>
                <p>&nbsp;</p>
                <p>
                  <span> Eran hombres de buscar en la arcilla</span>
                </p>
                <p>
                  <span> ingredientes de la vida y de la muerte.</span>
                </p>
                <p>
                  <span> el camino fue perforando</span>
                </p>
                <p>
                  <span> el vientre de la tierra.</span>
                </p>
                <p>
                  <span> &nbsp;</span>
                </p>
                <p>
                  <span> Viejo maestro de pueblo</span>
                </p>
                <p>
                  <span> historia de barro y sol</span>
                </p>
                <p>
                  <span> de tanto amasar la tierra</span>
                </p>
                <p>
                  <span> las coplas se hacen canción.</span>
                </p>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <p>
                  <span>
                    <b>
                      <span> Corazón pa’ Favaloro</span>
                    </b>
                  </span>
                </p>
                <p>&nbsp;</p>
                <p>
                  <span> Hay historias de entrega,</span>
                </p>
                <p>
                  <span> de seres que dan más allá de la vida,</span>
                </p>
                <p>
                  <span> siembran su semilla</span>
                </p>
                <p>
                  <span> con abnegación y fe, </span>
                </p>
                <p>
                  <span> con todo el saber,</span>
                </p>
                <p>
                  <span> que la ciencia otorga.</span>
                </p>
                <p>
                  <span> &nbsp;</span>
                </p>
                <p>
                  <span> Más se cotiza saber</span>
                </p>
                <p>
                  <span> que la brillantez del oro</span>
                </p>
                <p>
                  <span> es hora de devolver</span>
                </p>
                <p>
                  <span> corazón pa´ Favaloro.</span>
                </p>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <p>
                  <span>
                    <b>
                      <span>Madre de mi madre</span>
                    </b>
                  </span>
                </p>
                <p>&nbsp;</p>
                <p>
                  <span> Es como la arcilla pura</span>
                </p>
                <p>
                  <span> que protege la semilla del pan,</span>
                </p>
                <p>
                  <span> es como un ruido de fiesta</span>
                </p>
                <p>
                  <span> tu transparencia carnal.</span>
                </p>
                <p>
                  <span> Es muy sencillo y simple</span>
                </p>
                <p>
                  <span> el afecto que das,</span>
                </p>
                <p>
                  <span> la más hermosa forma de felicidad,</span>
                </p>
                <p>
                  <span> tejiendo recuerdos</span>
                </p>
                <p>
                  <span> de los tiempos de la infancia.</span>
                </p>
                <p>
                  <span> Te veo amasando el pan</span>
                </p>
                <p>
                  <span> con la harina del trabajo</span>
                </p>
                <p>
                  <span> con lágrimas y sudores, </span>
                </p>
                <p>
                  <span> madre de mi madre.</span>
                </p>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <p>
                  <span>
                    <b>
                      <span>Por ser cantor</span>
                    </b>
                  </span>
                </p>
                <p>&nbsp;</p>
                <p>
                  <span> Me brota un grillo de zamba</span>
                </p>
                <p>
                  <span> desde el rincón de la nostalgia</span>
                </p>
                <p>
                  <span> en la noche azul de mi provincia</span>
                </p>
                <p>
                  <span> cuando se entreveran las guitarras.</span>
                </p>
                <p>
                  <span> &nbsp;</span>
                </p>
                <p>
                  <span> El diapasón musical de mi alma</span>
                </p>
                <p>
                  <span> madruga alegre en la cacharpaya</span>
                </p>
                <p>
                  <span> y enarbola esta danza</span>
                </p>
                <p>
                  <span> en el rincón de la esperanza.</span>
                </p>
                <p>
                  <span> &nbsp;</span>
                </p>
                <p>
                  <span> Tu mirada triste parece explicar</span>
                </p>
                <p>
                  <span> aquella historia tan musical.</span>
                </p>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <p>
                  <span>
                    <b>
                      <span>Vinos del Palo Domingo</span>
                    </b>
                  </span>
                </p>
                <p>&nbsp;</p>
                <p>
                  <span> La uva es como un viento,</span>
                </p>
                <p>
                  <span> de a ratos gritos,</span>
                </p>
                <p>
                  <span> de a ratos silencio.</span>
                </p>
                <p>
                  <span> Seguimos buscando</span>
                </p>
                <p>
                  <span> en ese nostálgico</span>
                </p>
                <p>
                  <span> oscuro corazón mineral</span>
                </p>
                <p>
                  <span> la mano del sol</span>
                </p>
                <p>
                  <span> no tiene distancia</span>
                </p>
                <p>
                  <span> y hace sentir su sensual calor</span>
                </p>
                <p>
                  <span> para construir este paisaje</span>
                </p>
                <p>
                  <span> tan trino, tan sol, tan aire</span>
                </p>
                <p>
                  <span> tan Cafayate.</span>
                </p>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <p>
                  <span>
                    <b>
                      <span> changuito no te entregues</span>
                    </b>
                  </span>
                </p>
                <p>&nbsp;</p>
                <p>
                  <span> Quisiera darte toda la calidez</span>
                </p>
                <p>
                  <span> de mi amistad,</span>
                </p>
                <p>
                  <span> enseñarte a pintar</span>
                </p>
                <p>
                  <span> lo que miras</span>
                </p>
                <p>
                  <span> con tus ojos inexpertos.</span>
                </p>
                <p>
                  <span> &nbsp;</span>
                </p>
                <p>
                  <span> Las tentaciones son muchas</span>
                </p>
                <p>
                  <span> muy flaca la voluntad</span>
                </p>
                <p>
                  <span> cuando no se diferencia</span>
                </p>
                <p>
                  <span> la gracia de la maldad.</span>
                </p>
              </td>
              <td align="left" valign="top">
                <p>
                  <b>
                    <span> Pal Gringo Gauna</span>
                  </b>
                </p>
                <p>
                  <span> &nbsp;</span>
                </p>
                <p>
                  <span> Desde el misterio del agua</span>
                </p>
                <p>
                  <span> sube un niño corazón de carne</span>
                </p>
                <p>
                  <span> para alegrar en la tarde </span>
                </p>
                <p>
                  <span> el sereno canto de lo vivo.</span>
                </p>
                <p>
                  <span> &nbsp;</span>
                </p>
                <p>
                  <span> En el viento de los sueños</span>
                </p>
                <p>
                  <span> flor temprana de la infancia</span>
                </p>
                <p>
                  <span> su color, su ternura, su fragancia</span>
                </p>
                <p>
                  <span> se ha quedado en ti,</span>
                </p>
                <p>
                  <span> Gringo Gauna, a pesar de la distancia.</span>
                </p>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <p>
                  <span>
                    <b>Te mintió el amor</b>
                  </span>
                </p>
                <p>&nbsp;</p>
                <p>
                  <span> Multitudes de emociones rotas,</span>
                </p>
                <p>
                  <span> es lo que pinta el bandoneón</span>
                </p>
                <p>
                  <span> cuando solloza,</span>
                </p>
                <p>
                  <span> en los temas del amor.</span>
                </p>
                <p>
                  <span> &nbsp;</span>
                </p>
                <p>
                  <span> Que en un fracaso no marchite, </span>
                </p>
                <p>
                  <span> es necesario medites: </span>
                </p>
                <p>
                  <span> son las caras del amor, </span>
                </p>
                <p>
                  <span> noble, puro corazón.</span>
                </p>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <p>
                  <span>
                    <b>
                      <span>Las manos de don Juan Riera</span>
                    </b>
                  </span>
                </p>
                <p>&nbsp;</p>
                <p>
                  <span> Es general</span>
                </p>
                <p>
                  <span> del bien y de la paz,</span>
                </p>
                <p>
                  <span> con sus soldados,</span>
                </p>
                <p>
                  <span> por el hombre trabajaron</span>
                </p>
                <p>
                  <span> inventando para todos</span>
                </p>
                <p>
                  <span> una mesa repleta de pan,</span>
                </p>
                <p>
                  <span> sencillamente pan.</span>
                </p>
                <p>
                  <span> &nbsp;</span>
                </p>
                <p>
                  <span> Oficios que dignifican</span>
                </p>
                <p>
                  <span> rubias espigas de pan,</span>
                </p>
                <p>
                  <span> palomas de libertad,</span>
                </p>
                <p>
                  <span> en el pan de la humildad.</span>
                </p>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <p>
                  <span>
                    <b>
                      <span> Tucumán, viento y sol</span>
                    </b>
                  </span>
                </p>
                <p>&nbsp;</p>
                <p>
                  <span> Tucumán viento frutal</span>
                </p>
                <p>
                  <span> viene del cañaveral</span>
                </p>
                <p>
                  <span> la fragancia de tu suelo,</span>
                </p>
                <p>
                  <span> y la sal de tus abuelos.</span>
                </p>
                <p>
                  <span> &nbsp;</span>
                </p>
                <p>
                  <span> Quiero embriagarte de cantos,</span>
                </p>
                <p>
                  <span> acentos dulces y nuevos,</span>
                </p>
                <p>
                  <span> contagiarme de tu encanto</span>
                </p>
                <p>
                  <span> para decírselo a mi pueblo.</span>
                </p>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <p>
                  <span>
                    <b>
                      <span> Gatito mendocino</span>
                    </b>
                  </span>
                </p>
                <p>&nbsp;</p>
                <p>
                  <span> Decir país</span>
                </p>
                <p>
                  <span> es asomar por el paisaje,</span>
                </p>
                <p>
                  <span> radiante de sol.</span>
                </p>
                <p>
                  <span> Decir país </span>
                </p>
                <p>
                  <span> es trepar por la historia</span>
                </p>
                <p>
                  <span> traficada por la sangre</span>
                </p>
                <p>
                  <span> de todas las memorias.</span>
                </p>
                <p>
                  <span> Decir país</span>
                </p>
                <p>
                  <span> es evocar el canto</span>
                </p>
                <p>
                  <span> que nos enseñaron los abuelos.</span>
                </p>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <p>
                  <span>
                    <b>
                      <span>Padre chacarera</span>
                    </b>
                  </span>
                </p>
                <p>&nbsp;</p>
                <p>
                  <span> El hombre de tierra adentro</span>
                </p>
                <p>
                  <span> es silencio que camina</span>
                </p>
                <p>
                  <span> pulsando su propio encuentro</span>
                </p>
                <p>
                  <span> siempre lo topa la vida.</span>
                </p>
                <p>
                  <span> &nbsp;</span>
                </p>
                <p>
                  <span> El cielo tiene ventanas</span>
                </p>
                <p>
                  <span> por donde el sol nos despierta,</span>
                </p>
                <p>
                  <span> dejamos la puerta abierta</span>
                </p>
                <p>
                  <span> por la amistad mañanera</span>
                </p>
                <p>
                  <span> y un ritmo de chacarera</span>
                </p>
                <p>
                  <span> pone el alma de fiesta.</span>
                </p>
                <p>
                  <span> &nbsp;</span>
                </p>
                <p>
                  <span> En la Banda, una casa</span>
                </p>
                <p>
                  <span> cuando suena chacarera</span>
                </p>
                <p>
                  <span> ya se vuelve salamanca</span>
                </p>
                <p>
                  <span> por ser la casa paterna.</span>
                </p>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <p>
                  <span>
                    <b>
                      <span>Que no se calle el cantor</span>
                    </b>
                  </span>
                </p>
                <p>&nbsp;</p>
                <p>
                  <span> Vuelvo a los valles del vino</span>
                </p>
                <p>
                  <span> con la alegría y el corazón niño</span>
                </p>
                <p>
                  <span> a descubrir la semilla del canto</span>
                </p>
                <p>
                  <span> que baja por los ríos</span>
                </p>
                <p>
                  <span> con acento americano.</span>
                </p>
                <p>
                  <span> &nbsp;</span>
                </p>
                <p>
                  <span> Vuelvo a la tierra</span>
                </p>
                <p>
                  <span> donde vive la memoria de la raza.</span>
                </p>
                <p>
                  <span> Vuelvo siempre vuelvo</span>
                </p>
                <p>
                  <span> A llenar de júbilo los ojos</span>
                </p>
                <p>
                  <span> con las manos artesanas</span>
                </p>
                <p>
                  <span> que amasan ternura</span>
                </p>
                <p>
                  <span> en el barro del origen.</span>
                </p>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <p>
                  <span>
                    <b>
                      <span>Dos pájaros en vuelo</span>
                    </b>
                  </span>
                </p>
                <p>&nbsp;</p>
                <p>
                  <span> Ayer hice el camino sin retorno,</span>
                </p>
                <p>
                  <span> ayer nomás en el último tramo</span>
                </p>
                <p>
                  <span> dejamos la emoción y el asombro</span>
                </p>
                <p>
                  <span> en el gastado camino del canto.</span>
                </p>
                <p>
                  <span> &nbsp;</span>
                </p>
                <p>
                  <span> Ahora vuelvo a compartir la fiesta</span>
                </p>
                <p>
                  <span> la alegría multiplicada de tonadas,</span>
                </p>
                <p>
                  <span> a cantar con el pueblo</span>
                </p>
                <p>
                  <span> el pulsudo ritmo de lo nuestro.</span>
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </center>
      <div class="autores">
        <div class="autor">
          <h6>Eduardo Ceballos.</h6>
          <img
            src="https://res.cloudinary.com/lagexpress/image/upload/f_auto,q_auto,r_max,q_100,w_100,h_100//strapi/Foto_a321272643"
            alt=""
          />
        </div>
      </div>
      <CommentsFB />
    </Layout>
  )
}

export default Poemas
